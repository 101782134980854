
import { useLocation } from "react-router-dom";
import { HomeFilled } from "@ant-design/icons";

const Breadcrumbs = () => {
  const { pathname } = useLocation();

  const breadcrumbMap: Record<string, string> = {
    "/": "Home",
    "/client/account_setting": "Account setting",
    "/client/new_order": "New order",
    "/client/services": "Services",
    "/client/orders": "Orders",
    "/client/add_funds": "Add funds",
    "/client/api": "API",
    "/client/affiliates": "Affiliates",
    "/client/mass_order": "Mass order",
    "/client/tickets": "Tickets",
  };

  const getBreadcrumbParts = () => {
    const parts = pathname.split("/").filter(Boolean);
    const fullPath = `/${parts.join("/")}`;

    return fullPath in breadcrumbMap
      ? [{ label: breadcrumbMap[fullPath] }]
      : [{ label: "Unknown" }];
  };

  const breadcrumbParts = getBreadcrumbParts();

  return (
    <div className="flex items-center justify-between rounded-lg bg-white p-4 px-6 max-w-[1200px] mx-auto mb-6">
      <h3 className="text-[16px] font-medium leading-[1.167] text-[#121926]">
        {breadcrumbParts[breadcrumbParts.length - 1]?.label || "Home"}
      </h3>
      <div className="flex flex-nowrap gap-2 items-center text-[14px] text-[#697586]">
        <HomeFilled style={{ fontSize: "12px", color: "#2CA58D" }}/>
        {breadcrumbParts.map((part, index) => (
          <span key={index} className="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
            <span className="text-[14px] font-medium">{part.label}</span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
