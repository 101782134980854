import ordersRoute from "pages/Orders/route";
import newOrderRoute from "pages/NewOrder/route";
import servicesRoute from "pages/Services/route";
import addFundRoute from "pages/AddFunds/route";
import apiRoute from "pages/Api/route";
import affiliatesRoute from "pages/Affiliates/route";
import massOrder from "pages/MassOrder/route";
import accountSettingRoute from "pages/AccountSetting/route";
import ticketRoute from "pages/Tickets/route";

const route = [
  ordersRoute,
  newOrderRoute,
  servicesRoute,
  addFundRoute,
  affiliatesRoute,
  apiRoute,
  massOrder,
  accountSettingRoute,
  ticketRoute,
];

export default route;
