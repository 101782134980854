import { DATE_TIME_FORMAT } from "../enums/dateFormat";
import { ORDER_STATUS } from "../enums/order";

enum TYPE_FIELD {
  INPUT = "INPUT",
  SELECT = "SELECT",
  DATE_PICKER = "DATE_PICKER",
  TIME_PICKER = "TIME_PICKER",
  INPUT_NUMBER = "INPUT_NUMBER",
  INPUT_PASSWORD = "INPUT_PASSWORD",
  SELECT_SEARCH = "SELECT_SEARCH",
  TEXT_AREA = "TEXT_AREA",
  AUTO_COMPLETE = "AUTO_SEARCH",
  RANGE_PICKER = "RANGE_PICKER",
  MULTI_SELECT_SEARCH = "MULTI_SELECT_SEARCH",
  LOCAL = "LOCAL",
  LOCAL_COORDINATE = "LOCAL_COORDINATE",
  IMAGE = "IMAGE",
  PERCENT = "PERCENT",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  MULTI_ITEMS = "MULTI_ITEMS",
  CHECKBOX_GROUP = "CHECKBOX_GROUP",
  CALCULATE_INPUT = "CALCULATE_INPUT",
  DAY_PICKER = "DAY_PICKER",
  POPUP_INPUT = "POPUP_INPUT",
  MULTI_TEXT_ITEMS = "MULTI_TEXT_ITEMS",
  GET_LOCATION_BTN = "GET_LOCATION_BTN",
  WEEK_PICKER = "WEEK_PICKER",
  INPUT_NUMBER_ONLY = "INPUT_NUMBER_ONLY", // chỉ là số, không có thêm dấu phân tách: 2024 not 2.024
}

enum PAGE_SIZE_OPTIONS {
  DEFAULT_VALUE = 10,
  OPTION_5 = 5,
  OPTION_10 = 10,
  OPTION_15 = 15,
  OPTION_25 = 25,
  OPTION_50 = 50,
  OPTION_5_LABEL = "5 rows",
  OPTION_10_LABEL = "10 rows",
  OPTION_15_LABEL = "15 rows",
  OPTION_25_LABEL = "25 rows",
  OPTION_50_LABEL = "50 rows",
}

enum LOCAL_STORAGE_KEY {
  PAGE_SIZE = "page_size",
  ACCESS_TOKEN = "access_token",
  USERNAME = "username",
  MENUS = "menus",
  DISTRICT_OPTIONS = "DISTRICT_OPTIONS",
  WARD_OPTIONS = "WARD_OPTIONS",
  USER_ID = "userId",
  PAGE_PARAMS = "PAGE_PARAMS",
  PRICE_SPECIFIC = "PRICE_SPECIFIC",
  PRICE_SPECIFIC_KEY = "PRICE_SPECIFIC_KEY",
  APPRAISAL = "APPRAISAL",
  APPRAISAL_GIVE = "APPRAISAL_GIVE",
  APPRAISAL_RECEIVE = "APPRAISAL_RECEIVE",
  APPRAISAL_KEY = "APPRAISAL_KEY",
  DEBT = "DEBT",
  DEBT_KEY = "DEBT_KEY",
  CHECK_BOX = "CHECK_BOX",
  CHECK_BOX_KEY = "CHECK_BOX_KEY",
  PRICESHARED_COLLECTED = "PRICESHARED_COLLECTED",
  API_KEY = "API_KEY",
  ROLE_USER = "ROLE",
  EMAIL_USER = "EMAIL",
  REF_CODE = "REF_CODE",
  BALANCE_USER = "BALANCE",
}

export {
  TYPE_FIELD,
  DATE_TIME_FORMAT,
  PAGE_SIZE_OPTIONS,
  LOCAL_STORAGE_KEY,
  ORDER_STATUS,
};
